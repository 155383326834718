import $ from "jquery";
import queryString from "query-string";
import "select2";
import "select2/dist/css/select2.css";

window.__QS__ = queryString;

$(function () {
  $.fn.select2.amd.define(
    "select2/i18n/ja",
    [],
    require("select2/src/js/select2/i18n/ja")
  );
  var $statusElm = $("#job-status");
  var MAX_FAILED_REQUEST = 10;
  var failedRequestNumber = 0;
  var $progressElm = $("div[role=progressbar]", $statusElm);
  var urlParams = new URLSearchParams(window.location.search);
  var MAIN_END_POINT = $(".main-layout-wrap").data("main-endpoint");

  var isSearchTeam = urlParams.has("team");
  if (isSearchTeam) {
    var teamText = urlParams.get("team");
    var newOption = new Option(teamText, teamText, false, false);
    $("#team").append(newOption).trigger("change");
    setTimeout(function () {
      $(".js-search-constructions").trigger("click");
    }, 500);
  }

  $(document).on("click", ".export-excel-btn.construction", function () {
    setProgressPercentage($progressElm, "0");
    var currentPage = $(".J-paginationjs-page.active").data("num") || 1;
    var month = $(".js-current-date-time").attr("current-month");
    var year = $(".js-current-date-time").attr("current-year");
    var params = $("#formDataConstruction").serialize();
    var clientName = $("#client_name").val();
    var nameOfficial = $("#name_internal").val();
    var userInChargeProject = $("#user_in_charge_project").val();
    var userInChargeConstruction = $("#user_in_charge_construction").val();
    var team = $("#team").val();
    var methodName = $("#method_name").val();
    var div1 = $("#div1").val();
    var codeStart = $("#code_start").val();
    var codeEnd = $("#code_end").val();

    clientName = clientName.toString();
    nameOfficial = nameOfficial.toString();
    userInChargeProject = userInChargeProject.toString();
    userInChargeConstruction = userInChargeConstruction.toString();
    team = team.toString();
    methodName = methodName.toString();
    div1 = div1.toString();

    params += `&code_start=${codeStart}&code_end=${codeEnd}&client_name=${clientName}&name_internal=${nameOfficial}&user_in_charge_project=${userInChargeProject}&user_in_charge_construction=${userInChargeConstruction}&team=${team}&method_name=${methodName}&div1=${div1}&current_page=${currentPage}`;
    var savedSearchParams = localStorage.getItem("params_sort");
    if (savedSearchParams) params = savedSearchParams;

    if (month && year) {
      params = params + "&year=" + year + "&month=" + month;
    }

    $.ajax({
      url: "/constructions/export",
      dataType: "json",
      data: params,
    })
      .done(function (response, status, xhrOpts) {
        if (status === "success") {
          var jobId = response.jid;
          var intervalName = "job_" + jobId;
          $statusElm.toggleClass("hidden");

          window[intervalName] = setInterval(function () {
            getJobStatus(jobId, intervalName);
          }, 7000);
        }
      })
      .fail(function (err) {});
  });

  function getJobStatus(jobId, intervalName) {
    var jobStatusUrl = "/constructions/job_status";
    $.ajax({
      method: "POST",
      url: jobStatusUrl,
      data: {
        job_id: jobId,
      },
    })
      .done(function (response, status, xhrOpts) {
        if (status === "success") {
          var percentage = response.percentage;
          setProgressPercentage($progressElm, percentage || 0);

          if (response.status === "complete") {
            setProgressPercentage($progressElm, "100");
            setTimeout(function () {
              deleteIntervalJob(intervalName);
              setProgressPercentage($progressElm, "0");

              $statusElm.toggleClass("hidden");
              var exportUrl =
                "/constructions/export_download.xlsx" + "?id=" + jobId + "&export_model=construction_item";

              window.location.href = exportUrl;
            }, 500);
          } else if (
            (response.status === "retrying" || response.status === "queued") &&
            response.percentage == null
          ) {
            failedRequestNumber += 1;
          }
          if (failedRequestNumber >= MAX_FAILED_REQUEST) {
            $statusElm.toggleClass("hidden");
            deleteIntervalJob(intervalName);
          }
        } else {
          deleteIntervalJob(intervalName);
        }
      })
      .fail(function (err) {
        deleteIntervalJob(intervalName);
      });
  }

  function deleteIntervalJob(intervalName) {
    clearInterval(window[intervalName]);
    delete window[intervalName];
    failedRequestNumber = 0;
  }

  function setProgressPercentage($progressElm, percentage) {
    $progressElm
      .attr("aria-valuenow", percentage)
      .css("width", percentage + "%")
      .text(percentage + "%");
  }

  var descSorts = { desc_sorts: [] };
  var ascSorts = { asc_sorts: [] };

  $(document).on("click", ".construction-list th.sort", function () {
    var params = localStorage.getItem("params_search");
    var parsed = queryString.parse(params);

    // Reset sort
    parsed.sort_type = "construction_code";
    delete parsed.asc_sorts;
    delete parsed.desc_sorts;

    var sortItem = $(this).data("field");

    descSorts = { desc_sorts: [] };
    ascSorts = { asc_sorts: [] };

    if ($(this).hasClass("sort_desc")) {
      $(this).addClass("sort_asc");
      $(this).removeClass("sort_desc");

      if (_.includes(descSorts.desc_sorts, sortItem)) {
        descSorts.desc_sorts = descSorts.desc_sorts.filter(
          (item) => item !== sortItem
        );
      }

      ascSorts.asc_sorts.push(sortItem);
    } else {
      $(this).removeClass("sort_asc");
      $(this).addClass("sort_desc");

      if (_.includes(ascSorts.asc_sorts, sortItem)) {
        ascSorts.asc_sorts = ascSorts.asc_sorts.filter(
          (item) => item !== sortItem
        );
      }

      descSorts.desc_sorts.push(sortItem);
    }

    ascSorts.asc_sorts.push("construction_code");

    $("input[name=sort_type]").prop("checked", false);
    $("#sort_by_construction_code").prop("checked", true);

    var mergeParams = _.extend(descSorts, ascSorts);
    mergeParams = _.merge(mergeParams, parsed);
    params = queryString.stringify(mergeParams, { arrayFormat: "comma" });

    localStorage.setItem("params_sort", params);
    $("#sort").trigger("click");
  });

  function api(field, per_page = 10) {
    return `${MAIN_END_POINT}search_construction_fields?field=${field}&is_all_method_name=false&current_page=1&per_page=${per_page}`;
  }

  function apiConstruction(field, per_page = 10) {
    return `${MAIN_END_POINT}construction_fields?name=${field}&current_page=1&per_page=${per_page}`;
  }

  function searchMultiple(field, api, multipled) {
    $(`#${field}`).select2({
      ajax: {
        url: api,
        dataType: "json",
        quietMillis: 100,
        data: function (params) {
          return {
            keyword: params.term,
          };
        },
        processResults: function (data) {
          return { results: data };
        },
      },
      multiple: true,
      language: "ja",
      closeOnSelect: false,
      tags: multipled,
    });
  }

  function searchSingle(field, api, multipled) {
    $(`#${field}`).select2({
      ajax: {
        url: api,
        dataType: "json",
        quietMillis: 100,
        data: function (params) {
          return {
            keyword: params.term,
          };
        },
        processResults: function (data) {
          return { results: data };
        },
      },
      allowClear: true,
      placeholder: "",
      multiple: false,
      language: "ja",
      closeOnSelect: true,
      tags: multipled,
    });
  }

  searchSingle("code_start", api("construction_code", 20), true);
  searchSingle("code_end", api("construction_code", 20), true);
  searchMultiple("div1", api("div1", 1000), false);
  searchMultiple(
    "user_in_charge_project",
    api("user_in_charge_project", 1000),
    false
  );
  searchMultiple(
    "user_in_charge_construction",
    api("user_in_charge_construction", 1000),
    false
  );
  searchMultiple("team", api("team", 1000), false);
  searchMultiple("method_name", api("method_name", 1000), false);
  searchMultiple("client_name", apiConstruction("client_name", 1000), false);
  searchMultiple(
    "name_internal",
    apiConstruction("name_internal", 1000),
    false
  );
});
